define(['lodash', 'santa-components', 'santa-core-utils', 'componentsCore', 'coreUtils', 'formCommon/bi/errors', 'formCommon/bi/events', 'reactDOM', 'prop-types'], function (_, santaComponents, coreUtilsLib, componentsCore, coreUtils, biErrors, biEvents, ReactDOM, PropTypes) {
    'use strict';
    const metaSiteApplicationId = -666;
    const FORM_BUILDER_PATH = '_api/wix-forms/v1/submit-contact-form';

    function shouldIgnoreActivity(props) {
        return props.ignoreActivityReport || props.isTemplate;
    }

    function isSubscribeForm() {
        return this.getActivityName() === 'SubscribeFormActivity';
    }

    function handleSubmitMessage() {
        const successMessage = this.props.compData.successMessage || this.translatedKeys.successMessage;
        this.showMessage(successMessage);
    }

    function handleSubmitLink() {
        const successLink = this.props.compData.link;
        if (!successLink) {
            return;
        }

        const linkVal = coreUtils.linkRenderer.renderLink(successLink, this.props.linkRenderInfo, this.props.rootNavigationInfo);
        const successLinkData = coreUtils.wixUrlParser.parseUrl(this.props.linkRenderInfo, coreUtils.linkRenderer.getLinkUrlFromLinkProps(linkVal));
        if (!successLinkData) {
            return;
        }

        const anchorData = successLink.anchorDataId;
        _.assign(successLinkData, {anchorData});

        if (successLinkData.pageId === this.props.rootNavigationInfo.pageId) {
            if (anchorData) {
                this.props.scrollToAnchor(anchorData);
            }
        } else {
            this.props.navigateToPage(successLinkData);
        }
    }

    function countBy(arr, prop) {
        return (arr || []).reduce(function (acc, item) {
            return item[prop] ? acc + 1 : acc;
        }, 0);
    }

    function submitForm({includeEmail, onSuccess, onError}) {
        const {toEmailAddress, bccEmailAddress, formName} = this.props.compData;
        const fields = this.getFieldsForActivityReporting();

        const toEmailAddressField = {
            to: [{
                address: toEmailAddress || '',
                name: toEmailAddress || ''
            }]
        };

        const bccEmailAddressField = bccEmailAddress ? {
            bcc: [{
                address: bccEmailAddress,
                name: bccEmailAddress
            }]
        } : {};

        const getAppInstance = () => {
            const clientSpecMap = this.props.getClientSpecMapEntry(metaSiteApplicationId);
            return _.get(clientSpecMap, 'instance');
        };

        const editorMetaDataField = includeEmail ? {
            editorMetaData: {
                siteId: this.props.siteId,
                pageId: this.props.pageId,
                compDataId: this.props.compData.id
            }
        } : {};

        const types = isSubscribeForm.call(this) ? ['SUBSCRIBE'] : [];

        coreUtils.ajaxLibrary.ajax({
            type: 'POST',
            dataType: 'json',
            contentType: 'application/json; charset=utf-8',
            url: `${this.props.activityInfo.baseUrl}/${FORM_BUILDER_PATH}`,
            headers: {
                'Authorization': getAppInstance(),
                'x-wix-site-revision': this.props.siteRevision
            },
            data: _.merge(
                {
                    fields,
                    formName: formName || '',
                    types
                },
                toEmailAddressField,
                bccEmailAddressField,
                editorMetaDataField
            ),
            success: onSuccess,
            error: onError
        });
    }

    function onSubmitSuccess() {
        coreUtils.integrations.promoteAnalytics.trackEvent(this.props.siteApiForPromoteAnalytics, 'Lead');

        this.props.reportBI(biEvents.FORM_SUBMIT_SUCCESS, {
            componentId: this.props.id,
            componentType: this.props.structure.componentType
        });

        this.setState({
            mailSent: true
        });

        if (this.props.compData.onSubmitBehavior === 'message') {
            handleSubmitMessage.call(this);
        } else {
            handleSubmitLink.call(this);
        }

        cleanForm.call(this);
        this.isBusy = false;
    }

    function onSubmitError(response) {
        this.props.reportBI(biErrors.FORM_SUBMIT_FINAL_FALLBACK, {
            componentId: this.props.id,
            componentType: this.props.structure.componentType,
            errorDesc: 'Error occurred in Fallback Request',
            response: JSON.stringify(response)
        });

        this.setState({
            mailSent: false
        });
        this.showMessage(this.translatedKeys.error, true);

        this.isBusy = false;
    }

    function cleanForm() {
        this.setState(this.getCleanFormState());
    }

    function onSubmit(e) { // eslint-disable-line complexity
        e.preventDefault();
        if (this.shouldBlockSubmit && this.shouldBlockSubmit()) {
            this.blockSubmit(ReactDOM.findDOMNode(this));
            return;
        }
        reportCrmBi.call(this, biEvents.CONTACT_FORM_CLICK_SUBMIT, {isDynamic: this.props.isDynamicContactForm});
        const toEmail = this.props.compData.toEmailAddress;
        const isValidEmail = toEmail && toEmail !== 'a33012eff368a577d48f52f310c92140';
        if (!isValidEmail) {
            this.props.reportBI(biErrors.FORM_SUBMIT_INVALID_EMAIL, {email: toEmail});
        }
        const validationBiParams = {
            isDynamic: this.props.isDynamicContactForm,
            numOfFields: countBy(this.props.orderedFields, 'hidden'),
            numOfRequiredFields: countBy(this.props.orderedFields, 'required')
        };
        const isFormValid = this.isFormValid();
        const canSubmitNewRequest = !this.isBusy && isFormValid && !shouldIgnoreActivity(this.props);
        if (canSubmitNewRequest) {
            this.props.reportBI(biEvents.FORM_SUBMIT, {
                componentId: this.props.id,
                componentType: this.props.structure.componentType
            });

            reportCrmBi.call(this, biEvents.CONTACT_FORM_SUBMIT_VALIDATION_SUCCESS, validationBiParams);
            this.shouldSubmitFallbackRequest = true;
            this.isBusy = true;
            if (this.props.compData.onSubmitBehavior === 'message') {
                this.showMessage(this.translatedKeys.submitting);
            }

            submitForm.call(
                this,
                {includeEmail: true, onSuccess: onSubmitSuccess.bind(this), onError: onSubmitError.bind(this)}
            );
        } else if (!isFormValid) {
            reportCrmBi.call(this, biEvents.CONTACT_FORM_SUBMIT_VALIDATION_FAILURE, validationBiParams);
        }
    }

    function reportCrmBi(event, params) {
        if (this.props.biVisitorId) {
            this.props.reportBI(event, _.merge(params, {visitorId: this.props.biVisitorId}));
        }
    }

    /*

     The following functions must be implemented in a form component in order to use formMixin:
     getFormInitialState,
     getFormSkinProperties,
     getFormFields,
     getFieldsForActivityReporting,
     getFieldLabels,
     isFormValid,
     getActivityName,
     getInputName,
     getLangKeys,

     */
    return {
        mixins: [componentsCore.mixins.skinBasedComp, coreUtilsLib.timersMixins.timeoutsMixin],

        propTypes: {
            compMasterPageData: santaComponents.santaTypesDefinitions.ContactFormSantaTypes.compMasterPageData,
            siteApiForPromoteAnalytics: santaComponents.santaTypesDefinitions.ContactFormSantaTypes.siteApiForPromoteAnalytics,
            id: santaComponents.santaTypesDefinitions.Component.id.isRequired,
            pageId: santaComponents.santaTypesDefinitions.Component.pageId.isRequired,
            siteId: santaComponents.santaTypesDefinitions.RendererModel.siteId.isRequired,
            structure: santaComponents.santaTypesDefinitions.Component.structure.isRequired,
            compData: santaComponents.santaTypesDefinitions.Component.compData.isRequired,
            rootNavigationInfo: santaComponents.santaTypesDefinitions.Component.rootNavigationInfo.isRequired,
            shouldResetComponent: santaComponents.santaTypesDefinitions.RenderFlags.shouldResetComponent.isRequired,
            contactFormTranslations: santaComponents.santaTypesDefinitions.ContactFormSantaTypes.contactFormTranslations.isRequired,
            isMobileView: santaComponents.santaTypesDefinitions.isMobileView.isRequired,
            isPremiumUser: santaComponents.santaTypesDefinitions.isPremiumUser.isRequired,
            isTemplate: santaComponents.santaTypesDefinitions.isTemplate.isRequired,
            metaSiteId: santaComponents.santaTypesDefinitions.RendererModel.metaSiteId.isRequired,
            externalBaseUrl: santaComponents.santaTypesDefinitions.PublicModel.externalBaseUrl,
            scrollToAnchor: santaComponents.santaTypesDefinitions.scrollToAnchor.isRequired,
            navigateToPage: santaComponents.santaTypesDefinitions.navigateToPage.isRequired,
            reportBI: santaComponents.santaTypesDefinitions.reportBI.isRequired,
            linkRenderInfo: santaComponents.santaTypesDefinitions.Link.renderInfo.isRequired,
            activityInfo: santaComponents.santaTypesDefinitions.Activity.activityInfo,
            ignoreActivityReport: PropTypes.bool,
            isDynamicContactForm: santaComponents.santaTypesDefinitions.ContactFormSantaTypes.isDynamicContactForm.isRequired,
            userLanguage: santaComponents.santaTypesDefinitions.WixUserSantaTypes.userLanguage.isRequired,
            biVisitorId: santaComponents.santaTypesDefinitions.biVisitorId,
            siteRevision: santaComponents.santaTypesDefinitions.PublicModel.siteRevision,
            getClientSpecMapEntry: santaComponents.santaTypesDefinitions.getClientSpecMapEntry.isRequired
        },

        getInitialState() {
            this.shouldResetFields = this.props.shouldResetComponent;
            this.translatedKeys = this.getLangKeys(this.props.contactFormTranslations);
            this.translatedKeys.submitting = '…';
            return _.merge(this.getFormInitialState(), {
                $mob: this.props.isMobileView ? 'mobile' : 'desktop',
                $dir: this.props.compData.textDirection || 'left'
            });
        },

        showMessage(message, isError) {
            this.setState({
                notifications: {
                    message,
                    error: !!isError
                }
            });
        },

        componentWillReceiveProps(nextProps) {
            const newShouldResetFields = nextProps.shouldResetComponent;

            if (newShouldResetFields && newShouldResetFields !== this.shouldResetFields) {
                cleanForm.call(this);
            }
            this.shouldResetFields = newShouldResetFields;
            this.setState({
                $mob: nextProps.isMobileView ? 'mobile' : 'desktop',
                $dir: nextProps.compData.textDirection || 'left'
            });
        },

        getSkinProperties() {
            if (this.state.notifications.message) {
                this.registerReLayout();
            }
            return _.merge(this.getFormSkinProperties(this.translatedKeys), {
                submit: {
                    onClick: onSubmit.bind(this),
                    children: this.props.compData.submitButtonLabel || 'Send'
                }
            });
        }
    };
});
